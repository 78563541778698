<template>
    <div class="box">
        <el-button
            type="primary"
            size="medium"
            @click="downloadFile"
        >
            数据导出
        </el-button>
        <span class="m-coun">{{countMoeny}}</span>
        <div class="box-toolbar">
            <div class="box-item">
                <el-input
                    placeholder="手机号码\用户名\ID"
                    v-model="content"
                    class="input-with-select"
                    clearable
                    @keyup.enter.native="searchGo"
                >
                </el-input>
            </div>
            <div class="box-item">
                <el-button
                    icon="el-icon-search"
                    circle
                    @click="searchGo()"
                ></el-button>
            </div>
        </div>
        <el-table
            border
            height="70vh"
            v-loading="loading"
            :stripe="true"
            :data="lists"
        >
            <el-table-column
                width="90"
                class-name="text-mono"
                prop="id"
                label="Id"
            >
            </el-table-column>
            <el-table-column
                prop="pay_no"
                label="支付充值金额单号"
            >
            </el-table-column>
            <el-table-column
                prop="pay_price"
                label="支付金额（当前充值的金额）"
            >
            </el-table-column>
            <el-table-column
                prop="actual_pay_price"
                label="实际支付充值金额"
            >
            </el-table-column>
            <el-table-column
                prop="recharge_money_price"
                label="储值金额（给予用户的储值）"
            >
            </el-table-column>

            <el-table-column
                align="center"
                prop="user_money"
                label="用户充值后的金额"
            >
            </el-table-column>

            <el-table-column
                align="center"
                prop="username"
                label="用户昵称"
            >
            </el-table-column>

            <el-table-column
                align="center"
                prop="phone"
                label="手机号码"
            >
            </el-table-column>

            <el-table-column
                align="center"
                prop="pay_time"
                label="支付时间"
            >
            </el-table-column>
        </el-table>
        <div class="bp-pages">
            <page
                :pageSize="limit"
                :count="count"
                @change="pageChange"
                @changeSize="changeSize"
            >
            </page>
        </div>
    </div>
</template>

<script>
import IntegralService from '@admin/services/IntegralService'
import pagination from '@admin/mixins/PaginationMixin'
import Page from '@admin/components/Pagination'
import flatry from '@admin/utils/flatry'
import FileService from '@admin/services/FileService'

export default {
  name: 'MoneyLog',
  mixins: [pagination],
  components: { Page },
  data () {
    return {
      countMoeny: 0.00,
      content: '',
      loading: true,
      function: function (param) {
        return IntegralService.money(param)
      }
    }
  },
  async created () {
    const { data } = await flatry(IntegralService.getCountMoney())
    if (data) {
      this.countMoeny = data.data
    }
  },
  methods: {
    async searchGo () {
      this.page = 1
      this.loading = true

      let param = {
        limit: this.limit,
        page: this.page,
        content: this.content
      }
      this.where = { content: this.content }
      const data = await this.function(param)

      if (data) {
        this.lists = data.data
        this.count = data.count
      }

      this.loading = false
    },
    async downloadFile () {
      let param = {
        type: 'money-log'
      }
      const { data } = await flatry(FileService.downloadFile(null, param))
      if (data) {
        console.log(data)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
  .m-coun{
    padding: 20px;
    font-size: 20px;
    font-weight: 700;
  }
</style>
